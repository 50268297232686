const protocol = process.env.NEXT_PUBLIC_API_PROTOCOL;
// const protocol = 'http'
// const host = '0ba38fcf51ad.ngrok.io';
//  const host = '192.168.1.9:3000';
const host = process.env.NEXT_PUBLIC_API_HOST;
const hostUrl = `${protocol}://${host}/api/v1/`;

export const API = {
  protocol: protocol,
  host: host,
  // port: port,
  hostUrl: hostUrl,
};
