import { Box, Container, Typography } from '@mui/material';
import raiseFundIdea from 'assets/images/man1.svg';

import box from 'assets/images/box.svg';
// import bubble from 'assets/images/bubble.svg';
import chevronRightWhite from 'assets/images/chevronRightWhite.svg';
import { BigButton } from '../common/Buttons/BigButton';
import { AlreadyFunded } from './AlreadyFunded';
import Link from 'components/Link';
import { useUser } from 'context/user.context';
import { ApiGet } from 'helpers/Api/ApiData';
import { useEffect, useState } from 'react';

const Dot = () => {
  return (
    <Box
      bgcolor="#C4C4C4"
      borderRadius={10}
      m="12px"
      sx={{
        mx: {
          xs: '6px',
          md: '12px',
        },
        width: {
          xs: '4px',
          md: '6px',
        },
        height: {
          xs: '4px',
          md: '6px',
        },
      }}
    />
  );
};

interface PitchData {
  link: string;
  eventDate: Date;
}

const EventBox = () => {
  const [pitchData, setPitchData] = useState<PitchData | undefined>();

  const fetchpitchData = async () => {
    return await ApiGet('admin/pitch').then((res: any) => {
      setPitchData(res.data);
    });
  };

  const formatter = new Intl.DateTimeFormat('en-IN', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    hour12: true,
  });

  const getDate = (eventDate: Date) => {
    const date = new Date(eventDate);
    const formattedDate = formatter.format(date);
    const [day, month, year, time, amPm, timeZone] = formattedDate.split(' ');

    return `${month} ${day}, ${year} ${time.toUpperCase()} ${amPm.toUpperCase()} ${timeZone}`;
  };

  useEffect(() => {
    fetchpitchData();
  }, []);

  const link = pitchData ? pitchData.link : '/';
  const date = pitchData
    ? getDate(pitchData.eventDate)
    : '';

  return (
    <Link href={link} target="_blank" rel="noreferrer" noLinkStyle>
      <Box
        sx={{
          textAlign: {
            xs: 'center',
            md: 'start',
          },
        }}
      >
        <Box
          display="inline-block"
          // mt="140px"
          sx={{
            mt: {
              xs: '40px',
              md: '67px',
              xl: '140px',
            },
          }}
          position="relative"
          zIndex={10}
          textAlign="center"
        >
          <Box
            // display="flex"
            // borderRadius={30}
            // justifyContent="space-between"
            // alignItems="center"
            // bgcolor="rgba(235, 152, 153, 0.3)"
            // px={3}
            // sx={{
            //   '& p': {
            //     fontSize: {
            //       xs: 8,
            //       md: 14,
            //     },
            //   },
            // }}
          >
            <Typography
              fontWeight={500}
              fontSize={14}
              letterSpacing="0.02em"
              sx={{ opacity: 0.7 }}
            >
              
            </Typography>
            <Dot />
            <Typography
              fontWeight={500}
              fontSize={14}
              letterSpacing="0.02em"
              sx={{ opacity: 0.7 }}
            >
              {date}
            </Typography>
            <Dot />
            {pitchData && (
              <Typography
                fontWeight={500}
                fontSize={14}
                letterSpacing="0.02em"
                sx={{ opacity: 0.7, mr: '10px' }}
              >
                Register for the pitch event
              </Typography>
            )}
            {/* <img src={chevronRight} alt="chevron right icon" /> */}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export const Hero = () => {
  const [{ user }] = useUser();
  return (
    <>
      <Box
        bgcolor="#F3F9EC"
        position="relative"
        borderRadius="0px 0px 64px 64px"
      >
        <Container
          sx={{
            position: 'relative',
            // height: '100vh',
            height: 'calc(90vh - 72px)',
            minHeight: 700,
          }}
        >
          {/* <EventBox /> */}
          <Typography
            component="h1"
            position="relative"
            zIndex={20}
            sx={{
              fontSize: {
                xs: 50,
                md: 80,
              },
              lineHeight: {
                xs: '70px',
                md: '98px',
              },
              pt: {
                xs: '51px',
                md: '40px',
                xl: '65px',
              },
              px: {
                xs: '10px',
                md: 0,
              },
            }}
            fontWeight={800}
          >
            <Box
              component="span"
              sx={{
                background:
                  'linear-gradient(270.02deg, #4CBFA9 0.02%, rgba(122, 216, 198, 0.04) 99.98%)',
              }}
            >
              Raise funds
            </Box>{' '}
            <br /> for your startup
            <br />
            in 21 days!
          </Typography>
          <Box
            display="flex"
            sx={{
              justifyContent: {
                xs: 'center',
                md: 'flex-start',
              },
            }}
          >
            <Link
              noLinkStyle
              href={user ? '/create-startup-profile' : '/register'}
            >
              <BigButton
                sx={{
                  mt: {
                    xs: '20px',
                    xl: '20px',
                  },
                  position: 'relative',
                  zIndex: 10,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    '& img': {
                      height: {
                        xs: 12,
                      },
                    },
                  }}
                >
                  <Typography
                    fontWeight={700}
                    fontSize={24}
                    mr="18px"
                    sx={{
                      fontSize: {
                        xs: 16,
                        md: 24,
                      },
                    }}
                  >
                    Submit Pitch Deck
                  </Typography>
                  <img src={chevronRightWhite} alt="right arrow icon" />
                </Box>
              </BigButton>
            </Link>
          </Box>
          {/* <Box
            position="absolute"
            // bottom={113}
            // width="55%"
            left={0}
            display="flex"
            justifyContent="center"
            sx={{
              '& img': {
                // width: '100%',
                width: {
                  xs: '80%',
                  md: '100%',
                },
              },
              width: {
                xs: '100%',
                md: '55%',
              },
              // bottom: {
              //   xs: 335,
              //   md: 113,
              // },
              top: {
                // xs: 260,
                // md: '40vh',
                xs: 260,
                md: '56vh',
                xl: '50vh',
              },
            }}
          >
            <img src={raiseFundIllustration} alt="illustration" />
          </Box> */}
          <Box
            position="absolute"
            top={0}
            width="47%"
            sx={{
              '& img': {
                width: '100%',
              },
              display: {
                xs: 'none',
                md: 'inherit',
              },
            }}
            right={0}
          >
            <img src={raiseFundIdea} alt="illustration" />
          </Box>
          <Box
            position="absolute"
            // top={14}
            // left={-250}
            sx={{
              top: {
                xs: -2,
                md: 14,
              },
              left: {
                xs: -50,
                md: -250,
              },
              // left: -250,
              '& img': {
                // width: '100%',
                width: {
                  xs: '45%',
                  md: '100%',
                },
              },
            }}
          >
            <img src={box} alt="illustration" />
          </Box>
          {/* <Box
            position="absolute"
            top={0}
            right={0}
            display="flex"
            justifyContent="flex-end"
            sx={{
              '& img': {
                // width: '100%',
                width: {
                  xs: '40%',
                  md: '100%',
                },
                mr: {
                  xs: 0,
                },
              },
            }}
          >
            <img src={bubble} alt="illustration" />
          </Box> */}
        </Container>
      </Box>
      <AlreadyFunded />
    </>
  );
};
