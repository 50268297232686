import { styled } from '@mui/system';

export const BigButton = styled('button')(({ theme, disabled }) => ({
  textDecoration: 'none',
  fontFamily: 'inherit',
  fontSize: 24,
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  paddingTop: 22,
  paddingBottom: 22,
  backgroundColor: disabled
    ? 'rgba(39, 35, 67, 0.47)'
    : theme.palette.text.primary,
  border: 'none',
  boxShadow: disabled
    ? 'none'
    : `5px 5px 0px 1px ${theme.palette.primary.main}`,
  color: '#ffffff',
  fontWeight: 700,
  paddingLeft: 60,
  paddingRight: 60,
  cursor: disabled ? 'inherit' : 'pointer',
  [theme.breakpoints.down('md')]: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 42,
    paddingRight: 42,
  },
}));
