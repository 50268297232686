import { Box, Typography } from '@mui/material';

const Dot = () => <Box height={8} width={8} bgcolor="#1E3841" mx={2} my={4} />;

export const Heading1 = ({
  children,
  icon,
  xsFontSize,
}: {
  children: string;
  icon?: string;
  xsFontSize?: number;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="#BADEED"
    >
      <Dot />
      <Box display="flex" alignItems="center">
        {!!icon && (
          <Box mr={2} display="flex" alignItems="center">
            <img src={icon} alt={children} />
          </Box>
        )}
        <Typography
          component="h2"
          color="#1E3841"
          fontWeight={600}
          letterSpacing="0.01em"
          sx={{
            fontSize: {
              xs: xsFontSize || 16,
              md: 36,
            },
          }}
        >
          {children}
        </Typography>
      </Box>
      <Dot />
    </Box>
  );
};
